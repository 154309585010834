import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';

function handleSideNav(navTriggers) {

    var navToggleEvent = new CustomEvent('navToggle');

    navTriggers.on('click', (e) => {

        e.preventDefault();
        e.stopPropagation();

        let realTarget = getRealSVGTargetForIE(e.target);

        if (realTarget.nodeName.toLowerCase() !== 'span') {
            realTarget = $(realTarget).parents('span');
        }

        let navTrigger = $(realTarget);
        let targetId = navTrigger.attr('data-toggle-nav');
        let target = $('#' + targetId);
        target.toggle();
        navTrigger.toggleClass('open');

        document.dispatchEvent(navToggleEvent);

    });


}

function init(config) {

    const navTriggers = $(config.sideNavTrigger);
    if (navTriggers.length) {
        handleSideNav(navTriggers);
    }

}

export default { init: init }
