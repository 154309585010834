import select from 'dominus';
import Headroom from 'headroom.js';

/*
    This module configures and initialises Headroom.js which controls toggling of classes for a fixed header
    CSS needs to be provided and a base setup is included sass/components/components.header.js

    Docuents: http://wicky.nillia.ms/headroom.js/
 */

export function stickyMenu(header) {

    const headroom  = new Headroom(header, {
        "offset": 90,
        "tolerance": 5,
        "classes": {
            "initial": "animated",
            "pinned": "slideDown",
            "unpinned": "slideUp"
        },
        "onNotTop": function() {
            // Hides the header if the user is directed to a
            // fragment identifier on a page. Prevents eg.
            // speaker bio's from being hidden by the header.
            if(window.location.hash) {
                let classList = this.elem.classList,
                    classes = this.classes;

                classList.add(classes.unpinned);
            }
        }
    });
    headroom.init();
}

function init(config) {
    const header = select.findOne(config.header);
    const disableHeadroom = document.getElementById('disable_headroom_nav');
    if (header && !disableHeadroom) {
        stickyMenu(header);
    }
}

export default { init: init }
