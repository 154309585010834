import $ from "jquery";
const _body = $('body');

let menuIsOpen = false;

let _menuToggle;

function toggleMobileMenu (e) {
    e.preventDefault();

    var scrollTop;
    var $docEl = $('html, body');
    var $wrap = $('.mobile-menu');

    if (menuIsOpen) {
        // menu is open so close it
        _body.removeClass('mobile-menu--visible').addClass('mobile-menu--hidden');
        menuIsOpen = false;
        unlockBody();
    } else {
        //_menuToggle.text('Close');
        _body.removeClass('mobile-menu--hidden').addClass('mobile-menu--visible');
        menuIsOpen = true;
        lockBody();

    }



    function lockBody() {
        //if(window.pageYOffset) {
        //  $wrap.css({
        //    top: '60px'
        //  });
        //}

        //$docEl.css({
        //  height: "100%",
        //  overflow: "hidden"
        //});

        $docEl.addClass('overlay-open');
    }

    function unlockBody() {
        //$docEl.css({
        //  height: "",
        //  overflow: ""
        //});

        $docEl.removeClass('overlay-open');

        //$wrap.css({
        //  top: ''
        //});

        //window.scrollTo(0, scrollTop);
        //window.setTimeout(function () {
        //  scrollTop = null;
        //}, 0);

    }
}






export function init(config) {
    _menuToggle = $(config.mobileMenuTrigger).on('click', toggleMobileMenu);
}

export default { init: init }
