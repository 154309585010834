import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';

function handleMobileNav() {
    const forwardButtons = $('[data-nav-target]');
    forwardButtons.on('click', handleForwardClick);

    const backButtons = $('[data-nav-back]');
    backButtons.on('click', handleBackClick);
}

function handleForwardClick(e) {
    const body = $('body');
    const navPanelItems = $('[data-nav-panel-items]');

    e.preventDefault();
    e.stopPropagation();

    navPanelItems.css('display', 'none');

    let target = null;

    if (e.target.nodeName !== 'DIV') {
        target = $(e.target).parents('div');
    } else {
        target = $(getRealSVGTargetForIE(e.target));
    }

    const navTargetId = target.attr('data-nav-target');
    const navTargetPanel = target.attr('data-target-panel');
    if (navTargetId && navTargetPanel) {
        const navTarget = $(`#${ navTargetId }`);
        navTarget.css('display', 'block');

        if (navTargetPanel === '2') {
            body.addClass('large-panel--2-active');
            body.removeClass('large-panel--3-active');
        } else if (navTargetPanel === '3') {
            body.addClass('large-panel--3-active');
            body.removeClass('large-panel--2-active');
        }
    }
}

function handleBackClick(e) {
    const body = $('body');

    e.preventDefault();
    e.stopPropagation();

    let target = null;

    if (e.target.nodeName !== 'DIV') {
        target = $(e.target).parents('div');
    } else {
        target = $(getRealSVGTargetForIE(e.target));
    }

    const navTargetId = target.attr('data-nav-back');
    if (navTargetId) {
        const navTarget = $(`#${ navTargetId }`);
        navTarget.css('display', 'block');
    }

    if (body.hasClass('large-panel--2-active')) {
        body.removeClass('large-panel--2-active');
    } else if (body.hasClass('large-panel--3-active')) {
        body.removeClass('large-panel--3-active');
        body.addClass('large-panel--2-active');
    }
}

function init(config) {
    handleMobileNav();

    const body = $('body');

    const mobileMenuUrlPathEl = document.getElementById('mobile-menu-url-path');
    let mobileMenuUrlPath = window.location.pathname;
    if (mobileMenuUrlPathEl) {
        mobileMenuUrlPath = mobileMenuUrlPathEl.getAttribute('content');
    }

    const uriParts = decodeURIComponent(
        mobileMenuUrlPath.replace(/^\//, '').replace(/\/\s*$/, '')
    ).split('/');
    let slugStart = 'nav-panel-';

    if (uriParts.length >= 2) {
        body.addClass('large-panel--3-active');
        slugStart = `${ slugStart }3-`;
    } else if (uriParts.length === 1 && uriParts[0] !== '') {
        body.addClass('large-panel--2-active');
        slugStart = `${ slugStart }2-`;
    }

    const activeItem = [];

    for (let i = 0; i < uriParts.length; i++) {
        if (i <= 1) {
            activeItem.push(uriParts[i]);
        }
    }
    let activeSlug = $(`#${ slugStart }${ activeItem.join('-') }`);
    if (activeSlug.length) {
        activeSlug.css('display', 'block');
    } else if (activeItem.length > 1) {
        activeItem.pop();
        activeSlug = $(`#nav-panel-2-${ activeItem.join('-') }`);
        if (activeSlug.length) {
            activeSlug.css('display', 'block');
            body.removeClass('large-panel--3-active');
            body.addClass('large-panel--2-active');
        }
    } else {
        body.removeClass('large-panel--2-active');
    }
}

export default {
    init,
};
