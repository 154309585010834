import * as Sentry from '@sentry/browser';

function init(dsn) {
    if (__DEVELOPMENT) { return; }

    Sentry.init({
        dsn,
        environment: `${ __ENVIRONMENT }-browser`,
        release: __SENTRY_RELEASE || undefined,
        debug: false,
        /*
            // If you need to filter or modify any events you can do so here.
            beforeSend(event, hint) {
                if (filterCondition) {  return null }
                if (modifyCondition) {  event.user = 'Rich'; return event }
                return event;
            },
        */
    });

    // Sentry.setUser({ 'email': 'pat@octave.nz' });
}

init('https://74ccc974d0604dac8dbd03bb450c9005@o37692.ingest.sentry.io/5287341');
