import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';

function switchText(target, attribute) {
    target.each(function() {
        let relatedToggles = $(`[data-toggle=${this.id}]`);

        if (relatedToggles.length) {
            relatedToggles.each(function() {
                let relatedToggle = $(this);
                relatedToggle.html(relatedToggle.attr(attribute));
            });
        }
    })
}

function hideRelatedToggles(target) {
    target.each(function() {
        let relatedToggles = $(`[data-toggle=${this.id}]`);
        relatedToggles.hide();
    });
}

function showRelatedToggles(target) {
    target.each(function() {
        let relatedToggles = $(`[data-toggle=${this.id}]`);
        relatedToggles.show();
    });
}

function handleToggle(toggleTriggers) {

    toggleTriggers.on('click', (e) => {

        let realTarget = getRealSVGTargetForIE(e.target);

        let toggle = $(realTarget);
        let targetId = toggle.attr('data-toggle');
        let hideParent = toggle.attr('data-toggle-hide-parent');
        let toggleText = toggle.attr('data-toggle-text');
        let originalToggleText = toggle.attr('data-toggle-original-text');
        let toggleTextAppend = toggle.attr('data-toggle-text-append');
        let originalToggleTextAppend = toggle.attr('data-toggle-original-text-append');
        let target = $('#' + targetId);
        let usesGenericClass = false;
        if(target.length === 0) {
            target = $('.' + targetId);
            usesGenericClass = true;
        }

        let shouldHide = toggle.html().startsWith(toggleText);

        if (shouldHide) {
            target.hide();
        } else {
            target.show();
        }

        if (target.css("display") !== 'none') {
            toggle.html(toggleText);
            if (toggleTextAppend) {
                toggle.append(toggleTextAppend);
            }

            if (usesGenericClass) {
                switchText(target, 'data-toggle-text');
                hideRelatedToggles(target);
            }
        } else {
            toggle.html(originalToggleText);
            if (originalToggleTextAppend) {
                toggle.append(originalToggleTextAppend);
            }

            if (usesGenericClass) {
                switchText(target, 'data-toggle-original-text');
                showRelatedToggles(target);
            }
        }

        if (hideParent) {
            $('#' + hideParent).hide();
        }

        e.preventDefault();
        e.stopPropagation();

    });


}

function init(config) {

    const toggleTriggers = $(config.toggleTrigger);
    if (toggleTriggers.length) {
        handleToggle(toggleTriggers);
    }

}

export default { init: init }