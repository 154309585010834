import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';
import 'unfetch/polyfill';

function retrieveMoreNews(newsTrigger) {

    newsTrigger.on('click', (e) => {

        e.preventDefault();

        let target = getRealSVGTargetForIE(e.target);

        let pageType = $("#news-page-type");
        if (pageType) {
            pageType = pageType.attr('data-page-type');
        } else {
            pageType = ''
        }


        fetch(`${target.href}?type=${pageType}`, {
            credentials: 'same-origin'
        }).then((response) => {

            if (response.status === 200) {
                return response.text();
            }

        }).then(text => {
            let targetContainer = $("#more-news-target");
            targetContainer.replaceWith(text);
            targetContainer = $("#more-news-target");

            let nextPageHref = targetContainer.attr('data-next-page-href');

            if (nextPageHref) {
                newsTrigger.attr('href', nextPageHref);
            } else {
                newsTrigger.css('display', 'none');
            }
        });
    });




}

function handleFormUpdate(newsFormTrigger) {

    newsFormTrigger.on('change', (e) => {
        let realTarget = getRealSVGTargetForIE(e.target);
        let selectedOption = realTarget.options[realTarget.selectedIndex].value;

        if (selectedOption === '') {
            window.location = `${window.location.origin}${window.location.pathname}`
        } else {
            window.location = `${window.location.origin}${window.location.pathname}?type=${selectedOption}`
        }
    });

}

function init(config) {

    const newsTrigger = $(config.newsTrigger);
    if (newsTrigger.length) {
        retrieveMoreNews(newsTrigger);
    }

    const newsFormTrigger = $(config.newsFormTrigger);
    if (newsFormTrigger.length) {
        handleFormUpdate(newsFormTrigger);
    }

}

export default { init: init }
