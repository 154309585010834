
function init(config) {

    const galleries = $(config.gallery);
    if (galleries.length) {
        galleries.fancybox({
            loop: true
        });
    }

}

export default { init: init }