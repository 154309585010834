
function init(config) {

    let boxes = [
        'id_thelink',
        'id_thehub',
        'id_thepoint',
        'id_topreads',
        'id_apicenter',
    ];

    let general = $('#id_general_email_marketing');

    var check_general_disable = function() {
        if(general.is(':checked')) {
            boxes.forEach((id) => {
                $(`#${id}`).prop('checked', false);
            });
        }
    }

    // Otherwise if the form doesn't validate we end up with all 5 boxes checked.
    check_general_disable();

    general.on('change', (e) => {
        check_general_disable();
    });

    boxes.forEach((id) => {
        let box = $(`#${id}`);
        box.on('change', (e) => {
            if(box.is(':checked')) {
                general.prop('checked', false);
            }
        });
    });

}

export default { init: init }