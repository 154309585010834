import './modules/sentry';
import './modules/modernizr.custom';

import './patches';
import './polyfills';

import $ from 'jquery';

import { onPageReady } from './utils';

import header from './modules/header.js';
import mobileMenu from './modules/mobile.menu.js';
import map from './modules/googlemap.js'; // contains google map.
import tabs from './modules/tabs.js';
import toggle from './modules/toggle.js';
import dmsTrigger from './modules/dms.trigger.view.js';
import news from './modules/news.js';
import sideNav from './modules/side.nav.js';
import loginLogoutSearch from './modules/login.logout.search.js';
import deviceApplication from './modules/device.application.js';
import carousels from './modules/carousel.js';
import gallery from './modules/gallery.js';
import newsletterPreferences from './modules/newsletter.preferences.js';
import apicentreEoiForm from './modules/apicentre.eoi.form.js';
import apicentreCcaForm from './modules/apicentre.cca.form.js';
import apicentreHomepage from './modules/apicentre.homepage.js';


window.jQuery = $;
window.$ = $;
require('slippry/dist/slippry.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');

const siteConfig = {
    tabs: '#tabs',
    mobileMenuTrigger: '#burger',
    header: '.headroom_header',
    activeClass: 'active',
    sideNavTrigger: '[data-toggle-nav]',
    toggleTrigger: '[data-toggle]',
    dmsExternalTrigger: '[data-dms-external-view]',
    loginLogoutSearchTarget: '#login-logout-search-header',
    newsTrigger: '.load-more-news',
    newsFormTrigger: '[data-news-type-target]',
    deviceContactTrigger: '[data-input-checked-expand]',
    carousels: '[data-carousel]',
    gallery: '[data-fancybox]' };

function externaliseLinks() {
    const links = [].slice.call($('a'));

    if (links.length > 0) {
        links.forEach(it => {
            if (it.hasAttribute('data-no-externalise')) {
                return;
            }

            if (it.host !== window.location.host && it.host !== '') {
                it.setAttribute('target', '_blank');
                it.setAttribute('rel', 'noopener noreferrer');
            }
        });
    }
}

function initialise() {
    header.init(siteConfig);
    sideNav.init(siteConfig);
    mobileMenu.init(siteConfig);
    toggle.init(siteConfig);
    dmsTrigger.init(siteConfig);
    loginLogoutSearch.init(siteConfig);
    news.init(siteConfig);
    deviceApplication.init(siteConfig);
    carousels.init(siteConfig);
    gallery.init(siteConfig);
    newsletterPreferences.init(siteConfig);
    apicentreEoiForm.init(siteConfig);
    apicentreCcaForm.init(siteConfig);
    apicentreHomepage.init();

    if (window.mapConfigs) {
        window.mapConfigs.forEach((mapConfig) => {
            map.init(mapConfig);
        });
    }

    tabs.init(siteConfig);

    // Temp to make main column equal to side nav
    const sideNavigation = $('.side-nav');
    const sideNavHeight = sideNavigation.height();
    const mainContent = $('.main-content');
    mainContent.css('min-height', sideNavHeight + 150);

    document.addEventListener('navToggle', () => {
        mainContent.css('min-height', $('.side-nav').height() + 150);
    });

    // focus on input
    const input = $('.input');

    input.on('focus', function () {
        const fieldsetParent = $(this).closest('.fieldset');
        $(fieldsetParent).children('.label').addClass('active-label');
    });

    externaliseLinks();
}

/* Initialise app */
onPageReady(initialise);
