
function init(config) {

    const carousels = $(config.carousels);
    if (carousels.length) {
        carousels.slippry({
                //captions: 'image-caption',
                //captionsEl: '.image-caption',
                auto: true,
                onSliderLoad: (index) => {
                    carousels.parents('[data-carousel-parent]').css('visibility', 'visible');
                }
            }
        )
    }

}

export default { init: init }