let isApiProviderInput;
let isThirdPartyInput;


function init(conf) {
    const apicentreEOIForm = document.getElementById('apicentre-eoi-form');
    if ( apicentreEOIForm ) {
        initForm()
    }
}

function initForm() {

    isApiProviderInput = document.getElementById('id_enquiry_type_api_provider');
    isApiProviderInput.onchange = handleIsApiProvider;

    isThirdPartyInput = document.getElementById('id_enquiry_type_third_party');
    isThirdPartyInput.onchange = handleIsThirdParty;

    setInitialState()

}

function handleIsApiProvider() {

    const apiProviderSecondaryFieldsDiv = document.getElementById('api-provider-secondary-fields');

    const questionOneFirstInput = document.getElementById('id_api_provider_provier_of_paymentsnz_format_bank_accounts_0');
    const questionOneSecondInput = document.getElementById('id_api_provider_provier_of_paymentsnz_format_bank_accounts_1');

    const questionTwoFirstInput = document.getElementById('id_api_provider_is_registered_bank_or_deposit_taker_0');
    const questionTwoSecondInput = document.getElementById('id_api_provider_is_registered_bank_or_deposit_taker_1');

    if ( isApiProviderInput.checked ) {
        apiProviderSecondaryFieldsDiv.style.display = 'block'
    } else {
        apiProviderSecondaryFieldsDiv.style.display = 'none'

        questionOneFirstInput.checked = false
        questionOneSecondInput.checked = false

        questionTwoFirstInput.checked = false
        questionTwoSecondInput.checked = false
    }
}

function handleIsThirdParty() {

    const apiProviderSecondaryFieldsDiv = document.getElementById('third-party-secondary-fields');

    const questionOneFirstInput = document.getElementById('id_third_party_organisation_has_nz_bank_account_0');
    const questionOneSecondInput = document.getElementById('id_third_party_organisation_has_nz_bank_account_1');

    const questionTwoFirstInput = document.getElementById('id_third_party_organisation_has_nz_gst_number_0');
    const questionTwoSecondInput = document.getElementById('id_third_party_organisation_has_nz_gst_number_1');

    if ( isThirdPartyInput.checked ) {
        apiProviderSecondaryFieldsDiv.style.display = 'block'
    } else {
        apiProviderSecondaryFieldsDiv.style.display = 'none'

        questionOneFirstInput.checked = false
        questionOneSecondInput.checked = false

        questionTwoFirstInput.checked = false
        questionTwoSecondInput.checked = false
    }
}

function setInitialState() {

    if ( isApiProviderInput ) {
        handleIsApiProvider()
    }

    if ( isThirdPartyInput ) {
        handleIsThirdParty()
    }

}


export default { init }
