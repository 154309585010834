import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';

function handleTabs(tabBlock, activeClass) {

    let tabElements = $(tabBlock).find('li');

    var tabChangeEvent = new CustomEvent('tabChange');

    // Attach onclick handler to li
    if (tabElements.length) {
        tabElements.on('click', (e) => {

            // Remove active class from tab elements
            tabElements.removeClass(activeClass);

            let realTarget = getRealSVGTargetForIE(e.target);

            // Grab the target and add the active class to the parent node.
            let target = $(realTarget);

            if (realTarget.nodeName !== 'LI') {
                target = $(realTarget.parentNode);
            }
            target.addClass(activeClass);

            // Hide inactive contains and show active ones.
            tabElements.each((i, tab) => {

                let containerTarget = $(`#${$(tab).attr('data-target')}`);
                if (containerTarget) {
                    if (tab.id !== target[0].id) {
                        $(containerTarget).css('display', 'none');
                    } else {
                        $(containerTarget).css('display', 'block');
                    }

                    document.dispatchEvent(tabChangeEvent);
                }

            });

            // Push the browser history state.
            window.history.pushState(null, null, target.attr('data-url'));

            e.preventDefault();

        });
    }

}


function init(config) {
    const tabBlock = $(config.tabs);
    if (tabBlock) {
        handleTabs(tabBlock, config.activeClass);
    }
}

export default { init: init }
