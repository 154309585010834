import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';

function handleContactTrigger(contactTrigger) {

    var target = $("#" + contactTrigger.attr('data-input-checked-expand'));

    contactTrigger.on('change', (e) => {

        contactToggle(target, contactTrigger);
    });

    contactToggle(target, contactTrigger);


}

function contactToggle(target, trigger) {
    let isChecked = trigger[0].checked;
    if (isChecked) {
        target.css("display", "block");
    } else {
        target.css("display", "none");
    }
}

function init(config) {

    const deviceContactTrigger = $(config.deviceContactTrigger);
    if (deviceContactTrigger.length) {
        handleContactTrigger(deviceContactTrigger);
    }

}

export default { init: init }
