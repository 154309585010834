let differentBillingAddressInput;


function init(conf) {
    const apicentreCCAForm = document.getElementById('apicentre-cca-form');
    if ( apicentreCCAForm ) {
        initForm();
    }
}

function initForm() {
    differentBillingAddressInput = document.getElementById('id_is_billing_address_same');
    differentBillingAddressInput.onchange = handleBillingAddress;

    setInitialState();
}

function handleBillingAddress() {
    const billingAddressFieldsDiv = document.getElementById('billing-address-fields');
    if ( differentBillingAddressInput.checked ) {
        billingAddressFieldsDiv.style.display = 'none';
    } else {
        billingAddressFieldsDiv.style.display = 'block';
    }
}

function setInitialState() {
    if ( differentBillingAddressInput ) {
        handleBillingAddress();
    }
}


export default { init }
