import onResize from '../utils/onResize';

/**
 * This is the JavaScript to provide the client requested behaviour of swapping the image from the banner to the
 * image and text block on mobile. Also we swap them back on resize.
 * */

let copyOfBannerImage;
let imageSwapped = false;

function initAPICentreHomepage() {

    if (window.innerWidth <= 1099) {
        // we change the images from the banner to the image and text block'
        // make a copy of the image incase we need to reinstate it late
        copyOfBannerImage = document.querySelector('.banner--apicentre-homepage__image').cloneNode();

        if (copyOfBannerImage && !imageSwapped) {
            const textAndImageContainer = document.getElementById('api-center--image-and-text-container-image-container');
            if (textAndImageContainer) {
                // hide the first (original) image
                document.getElementById('api-center--image-and-text-container-image-container').firstElementChild.classList.add('u-hidden');

                // set the styling of the replacement image
                copyOfBannerImage.setAttribute('class', 'api-center--image-and-text-image');
                copyOfBannerImage.setAttribute('id', 'swapped-image');

                // append the banner image to the text and image container.
                textAndImageContainer.appendChild(copyOfBannerImage);
                imageSwapped = true;
            }
        }
    } else if (imageSwapped) {
        document.getElementById('swapped-image').remove();
        document.getElementById('api-center--image-and-text-image').classList.remove('u-hidden');
        imageSwapped = false;
    } else {
        document.getElementById('api-center--image-and-text-image').classList.remove('u-hidden');
    }
}

function setupEventListeners() {
    onResize(() => {
        initAPICentreHomepage();
    });
}

function init() {
    const apicentreHomepage = document.getElementById('apicentre-homepage');
    if (apicentreHomepage) {
        initAPICentreHomepage();
        setupEventListeners();
    }
}

export default { init };
