import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';

function handleDMSTrigger(dmsTriggers) {

    dmsTriggers.on('click', (e) => {

        let realTarget = getRealSVGTargetForIE(e.target);

        // Grab the target and add the active class to the parent node.
        let target = $(realTarget);

        var dmsViewEvent = new CustomEvent('dmsView', {'detail':
            {
                folderId: target.attr("data-dms-folder"),
                docId: target.attr("data-dms-external-view")
            }
        });
        document.dispatchEvent(dmsViewEvent);
        e.preventDefault();
    });


}

function init(config) {

    const dmsTriggers = $(config.dmsExternalTrigger);
    if (dmsTriggers.length) {
        handleDMSTrigger(dmsTriggers);
    }

}

export default { init: init }
