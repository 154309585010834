import 'unfetch/polyfill';
import mobileNav from './mobile.navigation.js';

function retrieveLoginLogoutSearchHeader(loginLogoutSearchTrigger) {

    fetch('/auth/check/', {
        credentials: 'same-origin',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        crossDomain: false
    }).then((response) => {

        if (response.status === 200) {
            return response.json();
        }

    }).then(json => {

        loginLogoutSearchTrigger.html(json.desktop);

        const mobileLoginButton = $("#mobile-nav-login-button");
        if (mobileLoginButton.length) {
            mobileLoginButton.replaceWith(json.mobile_login_button);
        }

        const mobileNavPortalMenu = $("#mobile-nav-portal-item");
        if (mobileNavPortalMenu.length) {
            mobileNavPortalMenu.replaceWith(json.mobile_nav_portal_menu);
        }


        // Toggle search visibility
        const inputSearch = $(".input-search");
        const searchButton = $("#main-search-icon");

        const connectNavItem = $("[data-portal-nav-item]");
        if (connectNavItem.length) {
            if (window.location.pathname.startsWith('/connect')) {
                connectNavItem.addClass('active');
            }
        }

        const portalNavSecondLevelItem = $("#nav-panel-2-portal-menu");
        if (portalNavSecondLevelItem.length) {
            portalNavSecondLevelItem.replaceWith(json.mobile_nav_portal_second_level_menu);

        }

        const portalNavThirdLevelItem = $("#nav-panel-3-portal-menu");
        if (portalNavThirdLevelItem.length) {
            portalNavThirdLevelItem.replaceWith(json.mobile_nav_portal_third_level_menu);
        }

        mobileNav.init();

        searchButton.on('click', (e) => {
            inputSearch.focus();
            inputSearch.addClass('input-search--active');
            searchButton.addClass("nav__link--search--hidden");
            $(".global-search__submit").addClass("global-search__submit--active");
            e.preventDefault();
        });

    });


}

function init(config) {

    const loginLogoutSearchTrigger = $(config.loginLogoutSearchTarget);
    if (loginLogoutSearchTrigger.length) {
        retrieveLoginLogoutSearchHeader(loginLogoutSearchTrigger);
    }

}

export default { init: init }
