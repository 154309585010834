const numeral = require('numeral');

export default function fformat(value, decimals = 0, useGrouping = true) {
    let decimalFormat = `0.${ '0'.repeat(decimals) }`;
    // Small floating point values cause trouble with numeral. We don't need that.
    if (!value || (Math.abs(value) < 10 ** -(decimals + 1))) {
        return numeral(0).format(decimalFormat);
    }

    if (useGrouping) {
        decimalFormat = `0,${ decimalFormat }`;
    }
    return numeral(value).format(decimalFormat);
}
